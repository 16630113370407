<template>
  <div class="home">
    <div class="app-down" v-if="domainShow">
      <div class="app-down-main">
        <div class="close" @click="domainShow = !domainShow">
          <i class="el-icon-circle-close"></i>
        </div>
        <vue-qr :text="AppDownUrl" :size="200"></vue-qr>
        <div class="text" >扫码下载APP</div>
      </div>
    </div>
    <div class="swiper-top-view">
      <Swiper
        :data="item"
        v-for="(item, index) in swiperTopData"
        :key="index"
      ></Swiper>
    </div>
    <div class="header">
      <div class="login">
        <img @click="register" class="item" src="../assets/images/index_register.png" alt="" />
        <img @click="login" class="item" src="../assets/images/index_login.png" alt="" />
      </div>
      <div class="service" @click="domainShow = !domainShow" style="">
        <div class="service_text" >
          <img src="../assets/images/service.png" alt="" />
        </div>
      </div>
      <div class="download">
        <img
          src="../assets/images/download-ios.png"
          alt=""
          @click="domainShow = !domainShow"
        />
        <img
          class="download_android"
          src="../assets/images/download-android.png"
          alt=""
          @click="domainShow = !domainShow"
        />
      </div>
      <div class="more">
        <img src="../assets/images/more.png" alt="" />
      </div>
    </div>
    <div class="nav">
      <div class="nav-one">
        <img
          @click="chooseModelShow('modeling')"
          src="../assets/images/modeling.png"
          alt=""
        />
        <img
          @click="chooseModelShow('artist')"
          src="../assets/images/artist.png"
          alt=""
        />
        <img
          @click="chooseModelShow('animation')"
          src="../assets/images/animation.png"
          alt=""
        />
      </div>
      <div class="nav-two">
        <img
          @click="chooseModelShow('illustrations')"
          src="../assets/images/illustrations.png"
          alt=""
        />
        <img @click="chooseModelShow('comic')" src="../assets/images/comic.png" alt="" />
        <img
          @click="chooseModelShow('design')"
          src="../assets/images/design.png"
          alt=""
        />
        <img src="../assets/images/gallery.png" alt="" />
      </div>
      <div class="nav-three">
        <img
          src="../assets/images/state-college.png"
          alt=""
          style="width: 24vw; position: relative; top: 0.6vw"
        />
        <img src="../assets/images/nft.png" alt="" to="/enterprise" @click="router('/nft/list')" />
        <img src="../assets/images/AI_index.png" alt="" to="/enterprise" @click="router('/nft/list')" />
        <img src="../assets/images/AI_bai_bao_xiang_index.png" title="AI百宝箱" alt="" to="/enterprise" @click="router('/ai_bai_bao_xiang')" />
      </div>
    </div>
    <div class="swiper-view">
      <Swiper
        class="swipwe_component"
        :data="item"
        v-for="(item, index) in swiperData"
        :key="index"
      ></Swiper>
    </div>
    <!-- 选择角色弹窗 -->
    <div class="select_role" v-if="chooseModel">
      <div
        to="/enterprise"
        class="img send_orders"
        @click="router('/enterprise')"
      ></div>
      <div
        to="/painter"
        class="img take_orders"
        @click="router('/painter')"
      ></div>
      <div class="close" @click="chooseModel = false"></div>
    </div>
    <router-view />
    <Login v-show="vuex_login_member_show"></Login>
  </div>
</template>

<script>
import store from '@/store/index';
import Swiper from "../components/Swiper";
import vueQr from "vue-qr";
import Login from "@/components/Login.vue"
export default {
  components: { Swiper, vueQr ,Login},
  data() {
    return {
      swiperTopData: [],
      chooseModel: false,
      swiperData: [],
      domainShow: false,
    };
  },
  mounted() {
    this.getSlideshowList();
    this.getHomeBgSwiper();
  },
  methods: {
    // 获取轮播图
    getHomeBgSwiper() {
      this.$api.home_bg_swiper.list().then((res) => {
        let images = [];
        res.data.forEach((item) => {
          images.push({ url: item.image });
        });
        this.swiperTopData = [
          {
            type: "home-top",
            images,
          },
        ];
      });
    },
    // 获取作品
    getSlideshowList() {
      this.$api.slideshow.list().then((res) => {
        let swiperData = [];
        res.data.forEach((item) => {
          let images = [];
          item.list.forEach((list) => {
            images.push([list.image]);
          });
          // 对数组进行排序
          images = this.paixu(images);
          swiperData.push({
            titleBg: this.getTitleBg(item.drawing_type),
            images,
          });
        });
        this.swiperData = swiperData;
      });
    },
    paixu(arr) {
      var result = []; //最终输出结果
      result.push(arr[0]); //排序之后直接将第1、2位的数据放入最终结果之中
      result.push(arr[1]);
      var l = 1; //定义该从何处插入数组值
      var boor = false; //这个定义是用于对循环进行限制，从第三个数据开始，每两个进行比对，第一个比对后，第二个就不用再进行循环了！
      for (var i = 2; i < arr.length; i++) {
        //两翼数据平衡第一次
        if (arr.length % 2 == 0) {
          //判断输入的数组长度是否为偶数
          if (boor) {
            boor = false;
            continue;
          } else {
            boor = true;
            result.splice(l, 0, arr[i + 1]);
            l++;
            result.splice(l, 0, arr[i]);
          }
        } else {
          if (i == arr.length - 1) {
            result.splice(l, 0, arr[i]);
          } else if (boor) {
            boor = false;
            continue;
          } else {
            boor = true;
            result.splice(l, 0, arr[i + 1]);
            l++;
            result.splice(l, 0, arr[i]);
          }
        }
      }
      //两翼数据平衡处理第二次
      var lIndex = 0; //初始result左部索引号
      var rIndex = result.length - 1; //初始result右部索引号
      var lSum = 0,
        rSum = 0; //存放左右两端数据的和
      // eslint-disable-next-line no-redeclare
      for (var i = 0; i < Math.floor(result.length / 2); i++) {
        //需要求和的次数为数组长度的一半，这里需要注意当数组长度为偶数时，默认左边少进行一次求和，保证最大数在中间部分
        if (result.length % 2 == 0) {
          rSum += parseInt(result[rIndex]);
          rIndex--;
          if (i < result.length / 2 - 1) {
            lSum += parseInt(result[lIndex]);
            lIndex++;
          }
        } else {
          lSum += parseInt(result[lIndex]);
          lIndex++;
          rSum += parseInt(result[rIndex]);
          rIndex--;
        }
      }
      if (lSum < rSum - 3) {
        //两翼数据平衡处理第三次，根据第二次结果来判断执行，减去3的目的是限制两边之差，若小于3则不用进行比对，直接输出便可
        var gap = Math.abs(lSum - rSum) - 3; //数值差的绝对值
        // eslint-disable-next-line no-redeclare
        for (var i = gap; i > 0; i--) {
          for (var j = Math.floor(result.length / 2); j < result.length; j++) {
            if (i == parseInt(result[j])) {
              var a = result.slice(j, j + 1);
              result.splice(j, 1);
              if (j == result.length) {
                result.splice(1, 0, a);
              } else {
                result.splice(result.length - j, 0, a);
              }
              return result; //满足条件，不用再进行数据交换了，结束整个循环；
            }
          }
        }
      } else {
        return result;
      }
    },
    // 获取类型背景图
    // 图的类型:modeling=建模,artist=原画,animation=动画,illustrations=插画,comic=漫画,design=设计,gallery=画廊
    getTitleBg(drawing_type) {
      let titleBg = "";
      switch (drawing_type) {
        case "illustrations":
          titleBg = "/images/swiper/illustrations.png";
          break;

        case "comic":
          titleBg = "/images/swiper/comic.png";
          break;

        case "animation":
          titleBg = "/images/swiper/animation.png";
          break;

        case "design":
          titleBg = "/images/swiper/design.png";
          break;

        case "gallery":
          titleBg = "/images/swiper/gallery.png";
          break;

        case "modeling":
          titleBg = "/images/swiper/modeling.png";
          break;

        case "artist":
          titleBg = "/images/swiper/artist.png";
          break;

        default:
          titleBg = "/images/swiper/comic.png";
          break;
      }
      return titleBg;
    },
    // 获取下载地址
    getAppDownloadAddress() {},
    // 下载
    downmb(key) {
      // 获取APP下载地址
      let data = {
        type: key,
      };
      this.$api.general.download_address(data).then((res) => {
        window.open(res.data);
      });
    },
    chooseModelShow(type) {
      console.log(this.drawing_type, type);
      this.$ku.vuex("drawing_type", type);
      this.chooseModel = true;
    },
    // 跳转页面
    router(url) {
      this.$ku.vuex("userType", url);
      this.$router.push(url);
    },
    register(){
      store.commit('$kuStore', {
        name: 'vuex_login_member_type', value: 'register'
      });
      store.commit('$kuStore', {
        name: 'vuex_login_member_show', value: true
      });
    },
    login(){
      store.commit('$kuStore', {
        name: 'vuex_login_member_type', value: 'login'
      });
      store.commit('$kuStore', {
        name: 'vuex_login_member_show', value: true
      });
    }
  },
};
</script>

<style lang="less" scoped>
.swiper-top-view {
  position: absolute;
  top: 0;
  z-index: -1;
  width: 100%;
}
.header {
  background: url(../assets/images/header.png) no-repeat;
  background-size: 100%;
  display: flex;
  justify-content: flex-end;
  height: 7.3vw;
  padding-bottom: 3%;
  .login{
    .item{
      width: 5rem;
      height: 5rem;
      margin-top: 3rem;
      cursor: pointer;
    }
    .item:nth-child(2) {
      margin-left: 1rem;
    }
  }
  .service {
    width: 25%;
    cursor: pointer;
    margin-top: 3rem;
    &_text {
      background: url(../assets/images/lighting_effect2.png) no-repeat;
      background-size: 110% 200% ;
      background-position: 60% 60% ;
      height: 100%;
      img {
        width: 12%;
        position: relative;
        top: 5%;
        left: 1%;
      }
    }
  }

  .download {
    width: 6%;
    margin: 0 2%;
    position: relative;
    top: -0.4vw;
    img {
      width: 100%;
      cursor: pointer;
    }
    &_android {
      position: relative;
      top: -0.4vw;
    }
  }

  .more {
    width: 5%;
    align-items: center;
    display: flex;
    justify-items: center;
    margin-right: 3%;
    img {
      width: 100%;
    }
  }
}
.nav {
  text-align: left;
  padding-top: 2%;
  padding-bottom: 9%;
  .nav-one {
    padding-left: 5%;
    margin-bottom: -5%;
  }
  .nav-two {
    margin-left: 12%;
    margin-bottom: -4%;
  }
  .nav-three {
    margin-left: 10%;
    margin-top: 2%;
    img {
      width: 20vw;
      margin-right: 0;
    }
    img:nth-child(4){
      width: auto;
      height: 15rem;
      margin-left: 5rem;
    }
  }
  img {
    cursor: pointer;
    width: 15%;
    margin-right: -3%;
  }
}

.select_role {
  background: url(../assets/images/select_role/bg.png) no-repeat;
  background-size: 100%;
  width: 60%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  padding: 5%;
  .send_orders {
    background: url(../assets/images/select_role/send_orders.png) no-repeat;
  }
  .take_orders {
    background: url(../assets/images/select_role/take_orders.png) no-repeat;
    background-position-y: bottom;
  }
  .img {
    background-size: 100%;
    height: 22vw;
    width: 100%;
    cursor: pointer;
  }

  .close {
    background: url(../assets/images/select_role/close.png) no-repeat;
    background-size: 100%;
    width: 3vw;
    height: 3vw;
    position: absolute;
    right: 5%;
    top: 7%;
    cursor: pointer;
  }
}
.swiper-view {
  .swipwe_component {
    margin-top: 5%;
  }
}
</style>
