
<template>
  <div>
    <div v-if="data.type == 'home-top'">
      <swiper :options="swiperHomeTopOption">
        <swiper-slide v-for="(item, index) in data.images" :key="index">
          <div>
            <img
              style="width: 100%; display: block"
              :src="image"
              alt=""
              v-for="(image, k) in item"
              :key="k"
            />
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="example-3d" v-else>
      <img class="bg-img" :src="data.titleBg" alt="" />
      <swiper class="swiper" :options="swiperOption">
        <swiper-slide v-for="(item, index) in data.images" :key="index">
          <div class="img">
            <img :src="image" alt="" v-for="(image, k) in item" :key="k" />
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-button swiper-button-prev" slot="button-prev">
          <img src="../assets/images/swiper/prev.png" alt="" />
        </div>
        <div class="swiper-button swiper-button-next" slot="button-next">
          <img src="../assets/images/swiper/next.png" alt="" />
        </div>
      </swiper>
      <img class="bg-img" src="../assets/images/swiper/bg-bottom.png" alt="" />
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "mySwiper",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    data: Object,
  },
  computed: {
    swiperOption() {
      let initialSlide = parseInt(this.data.images.length / 2);
      let swiperOption = {
        initialSlide:
          this.data.images.length % 2 == 0 ? initialSlide - 1 : initialSlide,
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 4,
        coverflowEffect: {
          rotate: -12,
          stretch: 0,
          depth: 400,
          modifier: 1,
          slideShadows: false,
        },
        pagination: {
          el: ".swiper-pagination",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      };
      return swiperOption;
    },
  },
  data() {
    return {
      swiperHomeTopOption: {
        loop: true, //环路，让Swiper看起来是循环的
        speed: 500, //速度
        freeMode: true, //根据惯性滑动可能不止一格且不会贴合
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        autoplay: {
          delay: 10000, //自动切换的时间间隔，单位ms
        },
        noSwiping: true, //设为true时，可以在slide上（或其他元素）增加类名'swiper-no-swiping'，使该slide无法拖动
        autoHeight: true, //自动高度
        spaceBetween: 3, //两个slide的间隔
        slidesPerView: "auto", //设置slider容器能够同时显示的slides数量
        observeParents: true, //当Swiper的父元素变化时Swiper更新。
      },
    };
  },
};
</script>

<style lang="less" scoped>
.example-3d {
  padding: 0 1%;
  position: relative;
  .bg-img {
    width: 100%;
  }
  &::before {
    content: "";
    position: absolute;
    bottom: 47%;
    left: 50%;
    width: 90%;
    height: 76%;
    transform: translate(-50%, 50%);
    background-color: #3e474d;
    z-index: -1;
    border-radius: 10px;
  }

  .swiper {
    margin-top: -4%;
    margin-bottom: -3.5%;
    padding-bottom: 3%;
    &-slide {
      display: flex;
      border: 2px solid #36cdff;
      .img {
        padding: 2%;
        width: 100%;
        img {
          width: 100%;
          display: block;
        }
      }
    }
    &-slide-active {
      background: url(../assets/images/swiper/img-bg.png) no-repeat;
      background-size: 100% 100%;
      border: none;
      .img {
        padding: 12% 5%;
        width: 100%;
      }
    }

    &-pagination {
      bottom: 0;
      /deep/ .swiper-pagination-bullet {
        width: 1vw;
        height: 1vw;
        background: url(../assets/images/swiper/bullet.png) no-repeat;
        background-size: 100%;
      }
      /deep/ .swiper-pagination-bullet-active {
        background: url(../assets/images/swiper/bullet-active.png) no-repeat;
        background-size: 100%;
      }
    }
    &-button-prev {
      left: 1.5%;
    }
    &-button-next {
      right: 1.5%;
    }
    &-button {
      width: 5%;
      &::after {
        content: "";
      }
      img {
        width: 100%;
      }
    }
  }
}
</style>
